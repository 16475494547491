import PaymentAuthEnDark from "../assets/presentation/images/payment-auth-en-dark.png";
import PaymentAuthEnLight from "../assets/presentation/images/payment-auth-en-light.png";
import PaymentAuthSkDark from "../assets/presentation/images/payment-auth-sk-dark.png";
import PaymentAuthSkLight from "../assets/presentation/images/payment-auth-sk-light.png";

import PassiveAuthEnDark from "../assets/presentation/images/passive-auth-en-dark.png";
import PassiveAuthEnLight from "../assets/presentation/images/passive-auth-en-light.png";
import PassiveAuthSkDark from "../assets/presentation/images/passive-auth-sk-dark.png";
import PassiveAuthSkLight from "../assets/presentation/images/passive-auth-sk-light.png";

import RedirectCardSk from "../assets/presentation/images/redirect-card-sk.png";
import RedirectSelectSk from "../assets/presentation/images/redirect-select-sk.png";

export const ImageByLanguageEnum = Object.freeze({
  PaymentAuthImg: [
    { language: "en", theme: "dark", img: <img src={PaymentAuthEnDark} alt="en" /> },
    { language: "en", theme: "light", img: <img src={PaymentAuthEnLight} alt="en" /> },
    { language: "sk", theme: "dark", img: <img src={PaymentAuthSkDark} alt="sk" /> },
    { language: "sk", theme: "light", img: <img src={PaymentAuthSkLight} alt="sk" /> }
  ],
  PassiveAuthImg: [
    { language: "en", theme: "dark", img: <img src={PassiveAuthEnDark} alt="en" /> },
    { language: "en", theme: "light", img: <img src={PassiveAuthEnLight} alt="en" /> },
    { language: "sk", theme: "dark", img: <img src={PassiveAuthSkDark} alt="sk" /> },
    { language: "sk", theme: "light", img: <img src={PassiveAuthSkLight} alt="sk" /> }
  ],
  RedirectCardImg: [
    //TODO: doplnit EN verziu
    { language: "en", img: <img src={RedirectCardSk} alt="en" /> },
    { language: "sk", img: <img src={RedirectCardSk} alt="sk" /> },
  ],
  RedirectSelectImg: [
    //TODO: doplnit EN verziu
    { language: "en", img: <img src={RedirectSelectSk} alt="en" /> },
    { language: "sk", img: <img src={RedirectSelectSk} alt="sk" /> },
  ],
});

export const getPaymentAuthImgByLanguageAndTheme = (language, theme) => {
  if (!theme) theme = "light";

  if (!language) language = "sk";

  return ImageByLanguageEnum.PaymentAuthImg.find((paymentAuth) => {
    return paymentAuth.language === language && paymentAuth.theme === theme;
  });
};

export const getPassiveAuthImgByLanguageAndTheme = (language, theme) => {
  if (!theme) theme = "light";

  if (!language) language = "sk";

  return ImageByLanguageEnum.PassiveAuthImg.find((passiveAuth) => {
    return passiveAuth.language === language && passiveAuth.theme === theme;
  });
};

export const getInlineCardImgByLanguage = (language) => {
  if (!language) language = "sk";

  return ImageByLanguageEnum.InlineCardImg.find((inlineCard) => {
    return inlineCard.language === language;
  });
};

export const getInlineSelectImgByLanguage = (language) => {
  if (!language) language = "sk";

  return ImageByLanguageEnum.InlineSelectImg.find((inlineSelect) => {
    return inlineSelect.language === language;
  });
};

export const getRedirectCardImgByLanguage = (language) => {
  if (!language) language = "sk";

  return ImageByLanguageEnum.RedirectCardImg.find((redirectCard) => {
    return redirectCard.language === language;
  });
};

export const getRedirectSelectImgByLanguage = (language) => {
  if (!language) language = "sk";

  return ImageByLanguageEnum.RedirectSelectImg.find((redirectSelect) => {
    return redirectSelect.language === language;
  });
};
