import React, { Suspense, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { getLocaleByShortcut } from "./utils/antdLocale.jsx";
import { ConfigProvider } from "antd";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";

import Routers from './components/Routers';
import global from "./translations/global.json";
import presenationGlobal from "./translations/presentation/global.json";
import Loader from './components/presentation/Loader';

const PresentationLayout = React.lazy(() => import("./components/presentation/PresentationLayout"));
const Themes = {
  light: `${process.env.PUBLIC_URL}/css/antd.css`,
  dark: `${process.env.PUBLIC_URL}/css/antd.dark.css`,
};

function App(props) {
  const language = useSelector((state) => state.language.language);
  const theme = useSelector((state) => state.userSettings.theme);
  const history = useHistory();
  const location = useLocation();

  let combinedTranslation = {
    "en": {
      ...global["en"],
      ...presenationGlobal["en"]
    },
    "sk": {
      ...global["sk"],
      ...presenationGlobal["sk"],
    },
    // "cz": {
    //   ...global["cz"],
    //   ...presenationGlobal["cz"],
    // },
    // "hu": {
    //   ...global["hu"],
    //   ...presenationGlobal["hu"],
    // },
    // "pl": {
    //   ...global["pl"],
    //   ...presenationGlobal["pl"],
    // },
    // "ro": {
    //   ...global["ro"],
    //   ...presenationGlobal["ro"],
    // },
    // "de": {
    //   ...global["de"],
    //   ...presenationGlobal["de"],
    // }
  }

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));
  }, []);

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={combinedTranslation[language]}
    >
      <ThemeSwitcherProvider themeMap={Themes} defaultTheme={theme}>
        <Suspense fallback={
          <Loader />
        }>
          <ConfigProvider locale={getLocaleByShortcut(language)}>
            <PresentationLayout>
              <Suspense fallback={
                <Loader />
              }>
                <Routers />
              </Suspense>
            </PresentationLayout>
          </ConfigProvider>
        </Suspense>
      </ThemeSwitcherProvider>
    </IntlProvider>
  );
}

export default App;
