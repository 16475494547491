import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox, Alert } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import {
    RightOutlined,
    LeftOutlined,
    CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link, Route } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getIntegrionInfoByLanguage } from "../../../enums/ImageByLanguageEnum";

export default function Void(props) {
    const theme = useSelector((state) => state.userSettings.theme);
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({ id: "global.presentation.name" }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.type" }),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.required" }),
            dataIndex: 'required',
            render: (required, record) => {
                return <Checkbox checked={required} disabled />;
            },
        },
        {
            title: intl.formatMessage({ id: "global.presentation.restriction" }),
            dataIndex: 'restriction',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.example" }),
            dataIndex: 'example',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.note" }),
            dataIndex: 'note',
        },
    ];

    const requestBody = [
        {
            name: 'dateTo',
            type: 'date',
            required: true,
            restriction: `-`,
            example: '2023-08-08T12:22:22.123Z',
            note: <>
                {intl.formatMessage({ id: "global.presentation.dateTo" })}{". "}
                {intl.formatMessage({ id: "global.presentation.accordingToIsoStandard" })}{": "}
                <Typography.Link href="https://www.iso.org/iso-8601-date-and-time-format.html" target="_blank">
                    ISO 8601
                </Typography.Link>
            </>,
        },
        {
            name: 'completionType',
            type: 'string',
            required: true,
            restriction: `Min: 1, Max: 64, ${intl.formatMessage({ id: "global.presentation.alphabeticUppercase" })}`,
            example: 'COMPLETE',
            note: <>
                {intl.formatMessage({ id: "global.presentation.void.completionType" })}{". "}
                {intl.formatMessage({ id: "global.presentation.enumeration" })}{": "}
                <Typography.Link href={RouterEnum.VoidCompletionType.Path} target="_blank">
                    CompletionType
                </Typography.Link>
            </>
        }
    ]

    return (
        <div className={[
            styles.main,
            theme === "dark" ? styles["dark-mode"] : "",
        ].join(" ")}>
            <div className={styles.section}>
                <Space direction="vertical">
                    <div>
                        <Typography.Title level={2}>
                            Void
                        </Typography.Title>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.void.text1" })}
                        </Typography.Text>
                    </div>
                </Space>
            </div>

            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <div className={styles["api-section"]}>
                        <div className={styles.object}>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestBody}
                                    bordered
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="horizontal">
                    <Link to={RouterEnum.Recurring.Path}>
                        <Button type="primary">
                            <LeftOutlined />
                            {intl.formatMessage({ id: "global.presentation.previous" })}
                        </Button>
                    </Link>
                    <Link to={RouterEnum.OnDemandTransaction.Path}>
                        <Button type="primary">
                            {intl.formatMessage({ id: "global.presentation.next" })}
                            <RightOutlined />
                        </Button>
                    </Link>
                </Space>
            </div>
        </div>
    );
}