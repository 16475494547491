import React from "react";
import { Route, Switch } from "react-router";
import { RouterEnum } from "../enums/RouterEnum";
import VoidPaymentIntentCreation from "../pages/presentation/VoidPaymentIntentCreation";
import Void from "../pages/presentation/Void";
import VoidCompletionType from "../pages/presentation/VoidCompletionType";
import VoidActiveAuthentication from "../pages/presentation/VoidActiveAuthentication";
import VoidCapture from "../pages/presentation/VoidCapture";
import VoidCancel from "../pages/presentation/VoidCancel";
import OnDemandPaymentIntentCreation from "../pages/presentation/OnDemandPaymentIntentCreation";
import PassiveOnDemandAuthentication from "../pages/presentation/PassiveOnDemandAuthentication";
import PassiveOnDemandInformation from "../pages/presentation/PassiveOnDemandInformation";
import OnDemandInvoke from "../pages/presentation/OnDemandInvoke";
import OnDemandCancel from "../pages/presentation/OnDemandCancel";
import OnDemandActiveAuthentication from "../pages/presentation/OnDemandActiveAuthentication";
import OnDemandTransaction from "../pages/presentation/OnDemandTransaction";
import OnDemandStatus from "../pages/presentation/OnDemandStatus";

// import ProtectedRoute from "./ProtectedRoute";

const Introduction = React.lazy(() => import("../pages/presentation/Introduction"));
const Security = React.lazy(() => import("../pages/presentation/Security"));
const Authentication = React.lazy(() => import("../pages/presentation/Authentication"));
const PaymentIntentCreation = React.lazy(() => import("../pages/presentation/PaymentIntentCreation"));
const PaymentIntent = React.lazy(() => import("../pages/presentation/PaymentIntent"));
const PaymentMethod = React.lazy(() => import("../pages/presentation/PaymentMethod"));
const Item = React.lazy(() => import("../pages/presentation/Item"));
const ItemType = React.lazy(() => import("../pages/presentation/ItemType"));
const Callback = React.lazy(() => import("../pages/presentation/Callback"));
const RefundRequestCallback = React.lazy(() => import("../pages/presentation/RefundRequestCallback"));
const Buyer = React.lazy(() => import("../pages/presentation/Buyer"));
const Billing = React.lazy(() => import("../pages/presentation/Billing"));
const Delivery = React.lazy(() => import("../pages/presentation/Delivery"));
const Recipient = React.lazy(() => import("../pages/presentation/Recipient"));
const PassiveAuthentication = React.lazy(() => import("../pages/presentation/PassiveAuthentication"));
const PassiveInformation = React.lazy(() => import("../pages/presentation/PassiveInformation"));
const Transaction = React.lazy(() => import("../pages/presentation/Transaction"));
const RefundTransactionResponse = React.lazy(() => import("../pages/presentation/RefundTransactionResponse"));
const Status = React.lazy(() => import("../pages/presentation/Status"));
const RefundStatus = React.lazy(() => import("../pages/presentation/RefundStatus"));
const AdditionalParam = React.lazy(() => import("../pages/presentation/AdditionalParam"));
const NotFound = React.lazy(() => import("../pages/presentation/NotFound"));
const RefundAuthentication = React.lazy(() => import("../pages/presentation/RefundAuthentication"));
const RefundRequest = React.lazy(() => import("../pages/presentation/RefundRequest"));
const ResponseCode = React.lazy(() => import("../pages/presentation/ResponseCode"));
const DisplayRedirect = React.lazy(() => import("../pages/presentation/DisplayRedirect"));
const Language = React.lazy(() => import("../pages/presentation/Language"));
const PassiveRefundAuthentication = React.lazy(() => import("../pages/presentation/PassiveRefundAuthentication"));
const PassiveRefundInformation = React.lazy(() => import("../pages/presentation/PassiveRefundInformation"));
const RefundTransaction = React.lazy(() => import("../pages/presentation/RefundTransaction"));
const RecurringPaymentIntentCreation = React.lazy(() => import("../pages/presentation/RecurringPaymentIntentCreation"));
const Recurring = React.lazy(() => import("../pages/presentation/Recurring"));
const RecurringCycle = React.lazy(() => import("../pages/presentation/RecurringCycle"));
const PassiveRecurringAuthentication = React.lazy(() => import("../pages/presentation/PassiveRecurringAuthentication"));
const PassiveRecurringInformation = React.lazy(() => import("../pages/presentation/PassiveRecurringInformation"));
const RecurringTransaction = React.lazy(() => import("../pages/presentation/RecurringTransaction"));
const RecurringStatus = React.lazy(() => import("../pages/presentation/RecurringStatus"));
const CancelRecurringAuthentication = React.lazy(() => import("../pages/presentation/CancelRecurringAuthentication"));
const CancelRecurringRequest = React.lazy(() => import("../pages/presentation/CancelRecurringRequest"));

const PassiveCardDataAuthentication = React.lazy(() => import("../pages/presentation/PassiveCardDataAuthentication"));
const PassiveCardDataInformation = React.lazy(() => import("../pages/presentation/PassiveCardDataInformation"));
const PassiveBankDataAuthentication = React.lazy(() => import("../pages/presentation/PassiveBankDataAuthentication"));
const PassiveBankDataInformation = React.lazy(() => import("../pages/presentation/PassiveBankDataInformation"));

const CardDataObject = React.lazy(() => import("../pages/presentation/CardDataObject"));
const BankDataObject = React.lazy(() => import("../pages/presentation/BankDataObject"));


function Routers(props) {
  const presentationRouter = () => {
    return (
      <Switch>
        <Route
          exact
          path={RouterEnum.Introduction.Path}
          render={() => <Introduction />}
        />
        <Route
          exact
          path={RouterEnum.Security.Path}
          render={() => <Security />}
        />
        <Route
          exact
          path={RouterEnum.Authentication.Path}
          render={() => <Authentication />}
        />
        <Route
          exact
          path={RouterEnum.PaymentIntentCreation.Path}
          render={() => <PaymentIntentCreation />}
        />
        <Route
          exact
          path={RouterEnum.RecurringPaymentIntentCreation.Path}
          render={() => <RecurringPaymentIntentCreation />}
        />
        <Route
          exact
          path={RouterEnum.PaymentIntent.Path}
          render={() => <PaymentIntent />}
        />
        <Route
          exact
          path={RouterEnum.PaymentMethod.Path}
          render={() => <PaymentMethod />}
        />
        <Route
          exact
          path={RouterEnum.Item.Path}
          render={() => <Item />}
        />
        <Route
          exact
          path={RouterEnum.Callback.Path}
          render={() => <Callback />}
        />
        <Route
          exact
          path={RouterEnum.RefundRequestCallback.Path}
          render={() => <RefundRequestCallback />}
        />
        <Route
          exact
          path={RouterEnum.ItemType.Path}
          render={() => <ItemType />}
        />
        <Route
          exact
          path={RouterEnum.Buyer.Path}
          render={() => <Buyer />}
        />
        <Route
          exact
          path={RouterEnum.Billing.Path}
          render={() => <Billing />}
        />
        <Route
          exact
          path={RouterEnum.Delivery.Path}
          render={() => <Delivery />}
        />
        <Route
          exact
          path={RouterEnum.Recipient.Path}
          render={() => <Recipient />}
        />
        <Route
          exact
          path={RouterEnum.PassiveAuthentication.Path}
          render={() => <PassiveAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveInformation.Path}
          render={() => <PassiveInformation />}
        />
        <Route
          exact
          path={RouterEnum.PassiveRecurringAuthentication.Path}
          render={() => <PassiveRecurringAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveRecurringInformation.Path}
          render={() => <PassiveRecurringInformation />}
        />
        <Route
          exact
          path={RouterEnum.CancelRecurringAuthentication.Path}
          render={() => <CancelRecurringAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.CancelRecurringRequest.Path}
          render={() => <CancelRecurringRequest />}
        />
        <Route
          exact
          path={RouterEnum.RefundAuthentication.Path}
          render={() => <RefundAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.RefundRequest.Path}
          render={() => <RefundRequest />}
        />
        <Route
          exact
          path={RouterEnum.PassiveRefundAuthentication.Path}
          render={() => <PassiveRefundAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveRefundInformation.Path}
          render={() => <PassiveRefundInformation />}
        />
        <Route
          exact
          path={RouterEnum.Transaction.Path}
          render={() => <Transaction />}
        />
        <Route
          exact
          path={RouterEnum.RecurringTransaction.Path}
          render={() => <RecurringTransaction />}
        />
        <Route
          exact
          path={RouterEnum.Recurring.Path}
          render={() => <Recurring />}
        />
        <Route
          exact
          path={RouterEnum.RecurringCycle.Path}
          render={() => <RecurringCycle />}
        />
        <Route
          exact
          path={RouterEnum.RefundTransactionResponse.Path}
          render={() => <RefundTransactionResponse />}
        />
        <Route
          exact
          path={RouterEnum.RefundTransaction.Path}
          render={() => <RefundTransaction />}
        />
        <Route
          exact
          path={RouterEnum.Status.Path}
          render={() => <Status />}
        />
        <Route
          exact
          path={RouterEnum.RecurringStatus.Path}
          render={() => <RecurringStatus />}
        />
        <Route
          exact
          path={RouterEnum.RefundStatus.Path}
          render={() => <RefundStatus />}
        />
        <Route
          exact
          path={RouterEnum.AdditionalParam.Path}
          render={() => <AdditionalParam />}
        />
        <Route
          exact
          path={RouterEnum.ResponseCode.Path}
          render={() => <ResponseCode />}
        />
        <Route
          exact
          path={RouterEnum.DisplayRedirect.Path}
          render={() => <DisplayRedirect />}
        />
        <Route
          exact
          path={RouterEnum.Language.Path}
          render={() => <Language />}
        />
        <Route
          exact
          path={RouterEnum.PassiveCardDataAuthentication.Path}
          render={() => <PassiveCardDataAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveCardDataInformation.Path}
          render={() => <PassiveCardDataInformation />}
        />
        <Route
          exact
          path={RouterEnum.PassiveBankDataAuthentication.Path}
          render={() => <PassiveBankDataAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveBankDataInformation.Path}
          render={() => <PassiveBankDataInformation />}
        />
        <Route
          exact
          path={RouterEnum.CardDataObject.Path}
          render={() => <CardDataObject />}
        />
        <Route
          exact
          path={RouterEnum.BankDataObject.Path}
          render={() => <BankDataObject />}
        />
        <Route
          exact
          path={RouterEnum.VoidPaymentIntentCreation.Path}
          render={() => <VoidPaymentIntentCreation />}
        />
        <Route
          exact
          path={RouterEnum.Void.Path}
          render={() => <Void />}
        />
        <Route
          exact
          path={RouterEnum.VoidCompletionType.Path}
          render={() => <VoidCompletionType />}
        />
        <Route
          exact
          path={RouterEnum.VoidAuthentication.Path}
          render={() => <VoidActiveAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.VoidCapture.Path}
          render={() => <VoidCapture />}
        />
        <Route
          exact
          path={RouterEnum.VoidCancel.Path}
          render={() => <VoidCancel />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandPaymentIntentCreation.Path}
          render={() => <OnDemandPaymentIntentCreation />}
        />
        <Route
          exact
          path={RouterEnum.PassiveOnDemandAuthentication.Path}
          render={() => <PassiveOnDemandAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.PassiveOnDemandInformation.Path}
          render={() => <PassiveOnDemandInformation />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandAuthentication.Path}
          render={() => <OnDemandActiveAuthentication />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandInvoke.Path}
          render={() => <OnDemandInvoke />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandCancel.Path}
          render={() => <OnDemandCancel />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandTransaction.Path}
          render={() => <OnDemandTransaction />}
        />
        <Route
          exact
          path={RouterEnum.OnDemandStatus.Path}
          render={() => <OnDemandStatus />}
        />
        <Route render={() => <NotFound />} />
      </Switch>
    );
  };

  return presentationRouter();
}

export default Routers;
