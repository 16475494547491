import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
    RightOutlined,
    LeftOutlined,
    CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link, Route } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getIntegrionInfoByLanguage } from "../../../enums/ImageByLanguageEnum";

export default function PassiveOnDemandInformation(props) {
    const theme = useSelector((state) => state.userSettings.theme);
    const language = useSelector((state) => state.language.language);
    const epUrl = "https://passive.besteron.com";
    const intl = useIntl();
    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);

        notification.success({
            placement: "bottomRight",
            message: intl.formatMessage({ id: "global.presentation.copied" }),
        });
    };

    const columns = [
        {
            title: intl.formatMessage({ id: "global.presentation.name" }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.type" }),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.required" }),
            dataIndex: 'required',
            render: (required, record) => {
                return <Checkbox checked={required} disabled />;
            },
        },
        {
            title: intl.formatMessage({ id: "global.presentation.example" }),
            dataIndex: 'example',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.note" }),
            dataIndex: 'note',
        },
    ];

    const requestHeader = [
        {
            name: 'Accept',
            type: 'string',
            required: true,
            example: 'application/json',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
        },
        {
            name: 'Content-type',
            type: 'string',
            required: true,
            example: 'application/json',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
        },
        {
            name: 'Authorization',
            type: 'string',
            required: true,
            example: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQmVzdGVyb24ifQ.Bnz2ha8ctC-NXe0x16_lRCbArwk3DI0mCJwCxIRGcDw',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} Bearer <Access token>`,
        }
    ]

    const requestBody = [
        {
            name: '-',
            type: 'List<string>',
            required: true,
            example: '["5s3ec6fe-2f15-47b8-88aa-4a08b2egb8dd", "7s3ef6fe-6f15-47b8-98aa-4a08b2ggb8dd"]',
            note: `${intl.formatMessage({ id: "global.presentation.transactionIdList" })}`,
        }
    ]

    const responseBody = [
      {
        name: 'onDemandTransaction',
        type: <>
            <Typography.Link href={RouterEnum.OnDemandTransaction.Path} target="_blank">
                onDemandTransaction
            </Typography.Link>
        </>,
        required: true,
        example: '-',
        note: <>
            {intl.formatMessage({ id: "global.presentation.object.description" })}{": "}
            <Typography.Link href={RouterEnum.OnDemandTransaction.Path} target="_blank">
                OnDemandTransaction
            </Typography.Link>
        </>
      }
    ]

    const responseBody2 = [
      {
        name: 'onDemandTransactions',
        type: <>
            {`List<`}
            <Typography.Link href={RouterEnum.OnDemandTransaction.Path} target="_blank">
                onDemandTransaction
            </Typography.Link>
            {`>`}
        </>,
        required: true,
        example: '-',
        note: <>
            {intl.formatMessage({ id: "global.presentation.object.description" })}{": "}
            <Typography.Link href={RouterEnum.OnDemandTransaction.Path} target="_blank">
              OnDemandTransaction
            </Typography.Link>
        </>
      }
    ]

    return (
        <div className={[
            styles.main,
            theme === "dark" ? styles["dark-mode"] : "",
        ].join(" ")}>
            <div className={styles.section}>
                <Space direction="vertical">
                    <div>
                        <Typography.Title level={2}>
                            {intl.formatMessage({ id: "global.presentation.obtainingOnDemandInformation" })}
                        </Typography.Title>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.passiveOnDemandInformation.text1" })}
                        </Typography.Text>
                        <Typography.Link href={RouterEnum.PassiveAuthentication.Path} target="_blank">
                            {intl.formatMessage({ id: "global.presentation.authentication" })}
                        </Typography.Link>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <Typography.Title level={5}>
                        {intl.formatMessage({ id: "global.presentation.paymentOnDemandInformation" })}
                    </Typography.Title>
                    <Space direction="horizontal">
                        <Tag color="blue">POST</Tag>
                        <Typography.Text>
                            {epUrl}/api/payment-intents/on-demand/{`<Transaction ID>`}
                        </Typography.Text>
                        <CopyOutlined onClick={() => copyToClipboard(`${epUrl}/api/payment-intents/on-demand`)} />
                    </Space>
                    <div className={styles["api-section"]}>
                        <div className={styles.left}>
                            {intl.formatMessage({ id: "global.presentation.passiveOnDemandInformation.text2" })}
                            <Divider orientation="left">
                                {intl.formatMessage({ id: "global.presentation.request" })}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestHeader}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.header" })}
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                            <Divider orientation="left">
                                {`${intl.formatMessage({ id: "global.presentation.response" })} (200 - OK)`}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={responseBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.sticky}>
                            <Tabs>
                                <Tabs.TabPane tab="HTTP" key="HTTP">
                                    <SyntaxHighlighter language="http" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`POST https://passive.besteron.com/api/payment-intents/on-demand/<Transaction ID>
Accept: application/json
Content-type: application/json
Authorization: Bearer <Access Token>`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Response" key="Response">
                                    <SyntaxHighlighter language="json" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`{
  "onDemandTransaction": {
    "transactionId": "39e1d961-32bd-4bna-8ef0-e7569d0a31a5",
    "createdAt": "2023-07-03T16:24:45.18615Z",
    "updatedAt": "2023-07-03T16:24:45.186226Z"
    "status": "Created",
    "onDemandTransactions": [
      "c9b906ba-9255-4332-9e6b-63dfd97232f0"
    ]
  }
}`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <Typography.Title level={5}>
                        {intl.formatMessage({ id: "global.presentation.paymentsOnDemandInformation" })}
                    </Typography.Title>
                    <Space direction="horizontal">
                        <Tag color="blue">POST</Tag>
                        <Typography.Text>
                            {epUrl}/api/payment-intents/on-demand
                        </Typography.Text>
                        <CopyOutlined onClick={() => copyToClipboard(`${epUrl}/api/payment-intents/on-demand`)} />
                    </Space>
                    <div className={styles["api-section"]}>
                        <div className={styles.left}>
                            {intl.formatMessage({ id: "global.presentation.passiveOnDemandInformation.text6" })}
                            <Divider orientation="left">
                                {intl.formatMessage({ id: "global.presentation.request" })}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestHeader}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.header" })}
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                            <Divider orientation="left">
                                {`${intl.formatMessage({ id: "global.presentation.response" })} (200 - OK)`}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={responseBody2}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.sticky}>
                            <Tabs>
                                <Tabs.TabPane tab="HTTP" key="HTTP">
                                    <SyntaxHighlighter language="http" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`POST https://passive.besteron.com/api/payment-intents/on-demand
Accept: application/json
Content-type: application/json
Authorization: Bearer <Access Token>

[
    "39f1d961-31bd-4bba-9ef0-e4569d0c31a5",
    "0c19d4d1-cf4f-4ea3-bf22-f43d8a78fe34",
]

`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Response" key="Response">
                                    <SyntaxHighlighter language="json" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`{
  "onDemandTransactions": [
    {
      "transactionId": "9f1d961-31bd-4bba-9ef0-e4569d0c31a5",
      "createdAt": "2023-07-03T16:24:45.18615Z",
      "updatedAt": "2023-07-03T16:24:45.186226Z"
      "status": "Created",
      "onDemandTransactions": [
        "c9b406ba-9267-4992-9e9b-63dfd17232f0"
      ]
    },
    {
      "transactionId": "0c19d4d1-cf4f-4ea3-bf22-f43d8a78fe34",
      "createdAt": "2023-07-03T16:25:12.092138Z",
      "updatedAt": "2023-07-03T16:25:12.092138Z"
      "status": "InProgress",
      "onDemandTransactions": [
        "f1398ce7-66d0-4c59-b32e-49eae6118e5a"
      ]
    }
  ]
}`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="horizontal">
                    <Link to={RouterEnum.PassiveOnDemandAuthentication.Path}>
                        <Button type="primary">
                            <LeftOutlined />
                            {intl.formatMessage({ id: "global.presentation.previous" })}
                        </Button>
                    </Link>
                    <Link to={RouterEnum.CancelRecurringAuthentication.Path}>
                        <Button type="primary">
                            {intl.formatMessage({ id: "global.presentation.next" })}
                            <RightOutlined />
                        </Button>
                    </Link>
                </Space>
            </div>
        </div>
    );
}