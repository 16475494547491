import React from "react";

import styles from "./Spinner.module.scss";

import SpinnerImg from "../../assets/presentation/images/spinner.svg";
import SpinnerLogoImg from "../../assets/presentation/images/spinner-logo.svg";

function Spinner(props) {
    return (
        <div className={styles.main}>
            <img className={styles.spinner} src={SpinnerImg} alt="spinner" />
            <img src={SpinnerLogoImg} alt="spinner-logo" />
        </div>
    );
}

export default Spinner;