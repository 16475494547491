export const RouterEnum = Object.freeze({
  Introduction: {
    Path: "/",
  },
  Security: {
    Path: "/security",
  },
  Authentication: {
    Path: "/authentication",
  },
  PaymentIntentCreation: {
    Path: "/payment-intent-creation",
  },
  RecurringPaymentIntentCreation: {
    Path: "/recurring-payment-intent-creation",
  },
  DisplayRedirect: {
    Path: "/payment-gateway-display/redirect",
  },
  PaymentIntent: {
    Path: "/objects/payment-intent",
  },
  PassiveAuthentication: {
    Path: "/payment-information/authentication",
  },
  PassiveInformation: {
    Path: "/payment-information/information",
  },
  PassiveRecurringAuthentication: {
    Path: "/payment-recurring-information/authentication",
  },
  PassiveRecurringInformation: {
    Path: "/payment-recurring-information/information",
  },
  PassiveRefundAuthentication: {
    Path: "/payment-refund-information/authentication",
  },
  PassiveRefundInformation: {
    Path: "/payment-refund-information/information",
  },
  RefundAuthentication: {
    Path: "/refund/authentication",
  },
  RefundRequest: {
    Path: "/refund/request",
  },
  CancelRecurringAuthentication: {
    Path: "/recurring/authentication",
  },
  CancelRecurringRequest: {
    Path: "/recurring/request",
  },
  AdditionalParam: {
    Path: "/objects/additional-param",
  },
  Item: {
    Path: "/objects/item",
  },
  Callback: {
    Path: "/objects/callback",
  },
  RefundRequestCallback: {
    Path: "/objects/refund-request-callback",
  },
  Buyer: {
    Path: "/objects/buyer",
  },
  Billing: {
    Path: "/objects/billing",
  },
  Delivery: {
    Path: "/objects/delivery",
  },
  Recipient: {
    Path: "/objects/recipient",
  },
  Transaction: {
    Path: "/objects/transaction",
  },
  RecurringTransaction: {
    Path: "/objects/recurring-transaction",
  },
  Recurring: {
    Path: "/objects/recurring",
  },
  RefundTransaction: {
    Path: "/objects/refund-transaction",
  },
  RefundTransactionResponse: {
    Path: "/objects/refund-transaction-response",
  },
  PaymentMethod: {
    Path: "/enumerations/payment-method",
  },
  ItemType: {
    Path: "/enumerations/item-type",
  },
  Status: {
    Path: "/enumerations/status",
  },
  RefundStatus: {
    Path: "/enumerations/refund-status",
  },
  Language: {
    Path: "/enumerations/language",
  },
  ResponseCode: {
    Path: "/enumerations/response-code",
  },
  RecurringCycle: {
    Path: "/enumerations/recurring-cycle",
  },
  RecurringStatus: {
    Path: "/enumerations/recurring-status",
  },
  PassiveCardDataAuthentication: {
    Path: "/payment-card-data-information/authentication",
  },
  PassiveCardDataInformation: {
    Path: "/payment-card-data-information/information",
  },
  PassiveBankDataAuthentication: {
    Path: "/payment-bank-data-information/authentication",
  },
  PassiveBankDataInformation: {
    Path: "/payment-bank-data-information/information",
  },
  CardDataObject: {
    Path: "/objects/card-data",
  },
  BankDataObject: {
    Path: "/objects/bank-data",
  },
  VoidPaymentIntentCreation: {
    Path: "/void-payment-intent-creation",
  },
  Void: {
    Path: "/objects/void",
  },
  VoidCompletionType: {
    Path: "/enumerations/void-completion-type",
  },
  VoidAuthentication: {
    Path: "/void/authentication",
  },
  VoidCancel: {
    Path: "/void/cancel",
  },
  VoidCapture: {
    Path: "/void/capture",
  },
  OnDemandPaymentIntentCreation: {
    Path: "/on-demand-payment-intent-creation",
  },
  PassiveOnDemandAuthentication: {
    Path: "/payment-on-demand-information/authentication",
  },
  PassiveOnDemandInformation: {
    Path: "/payment-on-demand-information/information",
  },
  OnDemandTransaction: {
    Path: "/objects/on-demand-transaction",
  },
  OnDemandAuthentication: {
    Path: "/on-demand/authentication",
  },
  OnDemandCancel: {
    Path: "/on-demand/cancel",
  },
  OnDemandInvoke: {
    Path: "/on-demand/invoke",
  },
  OnDemandStatus: {
    Path: "/enumerations/on-demand-status",
  },
});
